<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-22 17:12:55
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:00:03
 * @FilePath: /mediatom-web/src/viewsForManage/SDKDownload/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-spin :spinning="isLoading">
    <div class="sdk-download-container">
      <div class="sdk-download-wrapper">
        <div class="head">
          <h2>SDK集成工具</h2>
          <h3>特别说明</h3>
          <p style="font-size: 14px; line-height: 2">
            尊敬的开发者，在您集成Mediatom SDK以及其他第三方SDK之前，请务必详细阅读<span
              style="cursor: pointer; color: #36f"
              @click="skipTo('https://www.mediatom.cn/compdocs/detail?id=42')"
            >《Mediatom隐私政策》</span
            >和<span
              style="cursor: pointer; color: #36f"
              @click="skipTo('https://www.mediatom.cn/compdocs/detail?id=285')"
            >《Mediatom隐私合规指南》</span
            >，确认充分理解并同意本隐私政策及隐私合规指南的内容，一旦您接受Mediatom
            SDK提供的服务，即表示您已充分理解并同意本政策。您的集成必须符合相关法律法规、政策及标准，严格落实用户个人信息保护的相关要求，特别是保护个人信息和隐私的方法和措施。
          </p>
        </div>
        <div class="os-selected-box">
          <h3>第一步：选择系统</h3>
          <a-tabs v-model="query.os" @change="changeOs">
            <a-tab-pane :key="1" tab="Android"> </a-tab-pane>
            <a-tab-pane :key="0" tab="IOS" force-render> </a-tab-pane>
          </a-tabs>
        </div>
        <div class="public-sdk-wrapper">
          <div class="public-sdk-item" v-for="item in publishedSdkList" :key="item.id">
            <div class="public-sdk-box" :class="{ active: item.id === query.id }" @click="handleChangeSdk(item)">
              <div class="head">
                <div class="left">Mediatom SDK</div>
                <div class="right" @click.stop="skipTo('https://www.mediatom.cn/dockingdocs/menus')">开发文档></div>
              </div>
              <div class="version">
                <div class="version-title">
                  <span class="text">最新版本：{{ item.version }}</span>
                  <span>|</span>
                  <span class="updated">更新日期：{{ item.modifyTime }}</span>
                </div>
                <div class="version-desc">
                  <p v-for="(p, index) in item.modifyLogList" :key="index">
                    {{ p }}
                  </p>
                </div>
              </div>
              <div class="more">
                <span class="seemore" @click.stop="handleSeeMore">查看更多></span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="+query.os === 0" class="cocoapod-box">
          <span>是否Cocoapod接入：</span>
          <a-radio-group v-model="query.cocoapod">
            <a-radio :value="true"> 是（推荐） </a-radio>
            <a-radio :value="false"> 否 </a-radio>
          </a-radio-group>
        </div>
        <div class="plat-wrapper">
          <h3>第二步：选择平台</h3>
          <div class="plat-selected-list">
            <div class="plat-item" v-for="plat in platList" :key="plat.id">
              <div
                class="plat-box"
                @click="handleSelectPlat(plat)"
                :class="{ active: query.sourceSdkPackageIds.includes(plat.id) }"
              >
                <div class="palt-name">
                  <a-checkbox :checked="query.sourceSdkPackageIds.includes(plat.id)">{{ plat.name }}</a-checkbox>
                </div>
                <div class="plat-version">
                  <span class="version">版本{{ plat.version }}</span>
                </div>
                <div class="position-list">
                  <span
                    v-for="(child, c) in plat.positionNameList"
                    :key="c"
                  >{{ child }}{{ plat.positionNameList.length - 1 > c ? '、' : '' }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="create-wrapper">
          <h3>第三步：点击生成接入代码</h3>
          <div class="btn-box">
            <a-button class="btn" type="cust" @click="handleAccessCode">生成地址</a-button>
            <a
              v-if="downloadUrl && !isCocoapod"
              class="downloadLink"
              :href="downloadUrl"
              target="_blank"
              :download="fileName"
            >下载</a
            >
          </div>
          <div class="download-content">
            <a-input
              type="textarea"
              :autosize="true"
              placeholder=""
              v-model.trim="downloadUrl"
              :disabled="true"
            >
            </a-input>
          </div>
        </div>
      </div>
    </div>
    <DetailLogModal :os="query.os" :visible="logVisible" @modalCancel="logVisible = false"/>
  </a-spin>
</template>

<script>
import DetailLogModal from './DetailLogModal'
import { getPublishedSdkList, getSourceSdkAndPositionById, getSdkDownLoadUrl } from '@/api/sdkDownload'
export default {
  components: { DetailLogModal },
  data () {
    return {
      isLoading: false,
      query: {
        os: 1,
        id: undefined,
        sourceSdkPackageIds: [],
        cocoapod: true
      },
      publishedSdkList: [],
      platList: [],
      downloadUrl: undefined,
      fileName: undefined,
      isCocoapod: true,
      logVisible: false
    }
  },
  created () {
    this.getPublishedSdkList()
  },
  methods: {
    async getPublishedSdkList () {
      const { data = [] } = await getPublishedSdkList({ os: this.query.os })
      this.publishedSdkList = data
      if (data.length > 0) {
        this.query.id = data[0].id
        this.getPlatList()
      } else {
        this.platList = []
        this.query.id = undefined
        this.query.sourceSdkPackageIds = []
      }
    },
    skipTo (url) {
      window.open(url, '_blank')
    },
    changeOs () {
      this.getPublishedSdkList()
    },
    handleChangeSdk (row) {
      this.query.id = row.id
      this.getPlatList()
    },
    handleSeeMore () {
      this.logVisible = true
    },
    async getPlatList () {
      const { data = [] } = await getSourceSdkAndPositionById({
        id: this.query.id
      })
      this.platList = data
      this.query.sourceSdkPackageIds = []
    },
    handleSelectPlat (plat) {
      if (this.query.sourceSdkPackageIds.includes(plat.id)) {
        this.query.sourceSdkPackageIds = this.query.sourceSdkPackageIds.filter((item) => item !== plat.id)
      } else {
        this.query.sourceSdkPackageIds.push(plat.id)
      }
    },
    async handleAccessCode () {
      if (this.query.sourceSdkPackageIds.length === 0) {
        return this.$message.warning('请选择广告平台')
      }
      if (!this.query.id) {
        return this.$message.warning('请选择版本号')
      }
      this.isLoading = true
      const query = {
        ...this.query
      }
      if (+this.query.os !== 0) {
        query.cocoapod = undefined
      }
      const { data } = await getSdkDownLoadUrl(query)
        this.isCocoapod = query.cocoapod
      if (+this.query.os === 0 && this.query.cocoapod) {
        this.downloadUrl = data.join('\n')
      } else {
        this.fileName = data.replace('.', '%2E').replace('.', '%2E')
        this.downloadUrl =
          'https://app.mediatom.cn/download/' + this.fileName
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.sdk-download-container {
  display: flex;
  justify-content: center;
  .sdk-download-wrapper {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    margin: 15px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    .public-sdk-wrapper {
      padding: 10px;
      display: flex;
      align-items: stretch;
      position: relative;
      box-sizing: border-box;
      flex-wrap: wrap;
      .public-sdk-item {
        width: calc(1 / 3 * 100%);
        box-sizing: border-box;
        padding: 10px 20px;
        .public-sdk-box {
          min-height: 180px;
          height: 100%;
          border: 1.6px solid;
          border-color: #ccc;
          border-radius: 10px;
          cursor: pointer !important;
          padding: 20px;
          display: flex;
          flex-direction: column;
          &.active {
            border-color: @primary-color;
          }
          .head {
            display: flex;
            justify-content: space-between;
            .left {
              font-size: 12px;
            }
            .right {
              font-size: 12px;
              color: @primary-color;
              cursor: pointer;
            }
          }
          .version {
            display: flex;
            flex-direction: column;
            flex: 1 auto;
            margin-top: 10px;
            .version-title {
              color: #333;
              line-height: 25px;
              font-size: 12px;
              span {
                margin-right: 5px;
              }
            }
            .version-desc {
              flex-grow: 1;
              overflow: hidden;
              p {
                font-size: 12px;
                line-height: 22px;
                margin-bottom: 3px;
              }
            }
          }
          .more {
            height: 30px;
            line-height: 30px;
            color: @primary-color;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }
    .cocoapod-box{
      margin-bottom: 20px;
    }
    .plat-wrapper {
      .plat-selected-list {
        padding: 10px;
        display: flex;
        align-items: stretch;
        position: relative;
        box-sizing: border-box;
        flex-wrap: wrap;
        .plat-item {
          width: calc(1 / 3 * 100%);
          box-sizing: border-box;
          padding: 10px 20px;
          .plat-box {
            min-height: 140px;
            height: 100%;
            border: 1.6px solid;
            border-color: #ccc;
            border-radius: 10px;
            cursor: pointer;
            padding: 20px;
            display: flex;
            flex-direction: column;
            &.active {
              border-color: @primary-color;
            }
            .plat-version {
              .version {
                line-height: 30px;
                font-size: 12px;
                color: @primary-color;
              }
            }
          }
        }
      }
    }
    .create-wrapper{
      .btn-box{
        .btn{
          margin-right: 10px;
        }
        .downloadLink {
          cursor: pointer;
          display: inline-block;
          border: 1px solid #999;
          border-radius: 3px;
          background: #eee;
          width: 70px;
          height: 36px;
          vertical-align: top;
          text-align: center;
          line-height: 36px;
          font-size: 12px;
          color: #666;
        }
      }
      .download-content{
        margin-top: 10px;
      }
    }
  }
}
</style>
